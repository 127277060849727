








import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class SectionContent extends Vue {
  @Prop(Boolean) readonly fluid!: boolean;
}
