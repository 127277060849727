import { Vue, Component } from "vue-property-decorator";
import { AppModule } from "@/store/App";
import MarkdownIt from "markdown-it";
import Articles from "@/collections/Articles";
import Article from "@/models/Article";

@Component
export default class AppMixin extends Vue {
  articles: Articles = new Articles();
  article: Article | undefined = new Article();
  loading = false;

  category(sSlug: string) {
    return AppModule.categoryCollection.filter({ slug: sSlug }).first();
  }

  md(sValue: string) {
    return new MarkdownIt().renderInline(sValue);
  }
}
