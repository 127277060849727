
































import { Mixins, Component } from "vue-property-decorator";
import AppMixin from "@/mixins/App";
import Articles from "@/collections/Articles";
import SectionPage from "@/components/common/SectionPage.vue";

@Component({
  components: {
    SectionPage
  }
})
export default class PortfolioList extends Mixins(AppMixin) {
  articles: Articles = new Articles();

  mounted() {
    this.load();
  }

  async load() {
    const obCategory = this.category("portfolio");
    if (!obCategory) {
      return;
    }

    this.articles.category(obCategory.id).fetch();
  }

  get obCategory() {
    return this.category("portfolio");
  }
}
