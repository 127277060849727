























import { Vue, Component, Prop } from "vue-property-decorator";
import SectionContent from "@/components/common/SectionContent.vue";

@Component({
  components: {
    SectionContent
  }
})
export default class SectionPage extends Vue {
  @Prop(String) readonly title!: string;
  @Prop(String) readonly subtitle!: string;

  get hasContentSlot() {
    return this.$_.has(this.$slots, "content");
  }
}
